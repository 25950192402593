<template>
  <subsite-header :links="links" />
  <div class="span8 move2 my-7 pt-6">
    <SubsiteTitle text="Gruppelivsforsikringer administreret af Forenede Gruppeliv" />
    <p>
      Nogle af de forsikringer, vi tilbyder, administreres af Administrationsaktieselskabet Forenede Gruppeliv (FG). Det
      betyder, at Forenede Gruppeliv er forsikringsformidler, og at det er dem, der f.eks. står for behandlingen af
      sager på disse forsikringer.
    </p>
    <p>Det vil stå i din dækningsoversigt, hvis din forsikring administreres af Forenede Gruppeliv.</p>
    <SubsiteSubTitle text="Ejerselskaberne" />
    <p>Forenede Gruppeliv ejes ligeligt af følgende selskaber, som alle hæfter solidarisk for gruppelivsaftalerne:</p>
    <ul>
      <li>Norli Liv og Pension Livsforsikring A/S, Midtermolen 1, 2100 København Ø, CVR-nr. 64145711</li>
      <li>Danica Pension, Livsforsikringsaktieselskab, Bernstorffsgade 40, 1577 København V, CVR-nr. 24256146</li>
      <li>Sampension Livsforsikring A/S, Tuborg Havnevej 14, 2900 Hellerup, CVR-nr. 55834911</li>
      <li>Velliv, Pension & Livsforsikring A/S, Lautrupvang 10, 2750 Ballerup, CVR-nr. 24260577</li>
    </ul>
    <p>Norli modtager vederlag, når vi formidler FG’s gruppelivsforsikringer.</p>
  </div>
  <FooterComponent />
</template>

<script setup lang="ts">
import FooterComponent from '@/components/Footer/Footer.vue'
import SubsiteHeader from '@/components/Subsites/SubsiteHeader.vue'
import SubsiteSubTitle from '@/components/Subsites/SubsiteSubTitle.vue'
import SubsiteTitle from '@/components/Subsites/SubsiteTitle.vue'

const links = [
  {
    title: 'Vores løsninger',
    href: '#loesninger',
  },
  {
    title: 'Spørgsmål og svar',
    href: '#faq',
  },
  {
    title: 'Om os',
    href: '#om',
  },
  {
    title: 'For pensionsbranchen',
    href: '#pensions',
  },
  {
    title: 'Kontakt',
    href: '#kontakt',
  },
]
</script>

<style scoped></style>
